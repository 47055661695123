<template>
  <el-tooltip popper-class="el-tooltip" :visible-arrow="false" effect="dark" v-delTabIndex :content="$t('search_misjudgment')/*誤判*/" placement="bottom">
    <div class="mis-judgment" @click.stop="setEventMisjudged(event)">
      <img v-if="event.misjudged === 0" src="@/assets/icons/misjudged-disable.svg" alt="">
      <img v-else src="@/assets/icons/misjudged-yellow.svg" alt="">
    </div>
  </el-tooltip>
</template>

<script>
import { apiEditBoviaLprEvent } from '@/api/index.js'

export default {
  name: 'EventMisjudgment',
  props: {
    event: {
      type: Object,
      required: true
    },
    api: {
      type: Function,
      default: apiEditBoviaLprEvent
    }
  },
  data() {
    return {}
  },
  methods: {
    async setEventMisjudged(event) {
      let data = {
        event: [{id: event.id}],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await this.api(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mis-judgment {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

img {
  width: 100%;
  height: 100%;
  vertical-align: baseline;
}

</style>